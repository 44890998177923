

.altworx {
    color: black;
    margin: 500px 2.5% 0px ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    border-radius: 15px;
}


.helfer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.helfer img {
    width: 50%;
    height: auto;
    padding: 80px 0px;
}

.moto { 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: auto;
    background-color: var(--transparent-white);
    color: var(--black);
    border-radius: 15px;
    padding: 20px 5%;
    backdrop-filter: blur(4px);
    text-align: center;
    color: var(--white);
    font-size: 1.2rem;
}

.moto-left {
    text-align: center;
    margin: 0 5%;
}

.moto-right {
    width: 100%;
    margin: 0px;
    padding: 0px;
}


@media screen and (max-width: 1024px) {


    .helfer img {
        width: 60%;
        height: auto;
        padding: 80px 0px;
    }
    .moto {
        flex-direction: column;
    }
    .moto-left {
        padding: 10px;
        margin: 0;
        display: flex;
        justify-content: center;
        padding: 10px 0px;
        font-size: 0.8rem;
        
    }
}

@media screen and (max-width: 850px) {
    .altworx {
        margin: 350px 2.5% 0px ;
    }
}