

.reference {
    width: 95%;
    height: auto;
    background-color: var(--transparent-white);
    padding: 2.5%;
    margin: 2.5%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    backdrop-filter: blur(4px);
}

.reference_logo {
    height: 250px;
    width: 250px;
    background-size: contain;

}

.cd_cargo {
  background-image: url(../assets/CD_Cargo_Small.png); 
  background-repeat: no-repeat;
  background-position: center;
}

.o2 {
  background-image: url(../assets/O2_Small.png); 
  background-repeat: no-repeat;
  background-position: center;
}

.cez {
  background-image: url(../assets/CEZ_Small.png); 
  background-repeat: no-repeat;
  background-position: center;
}

.lovochemie {
  background-image: url(../assets/Lovo_Small.png); 
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 1024px) {


  .cd_cargo {
    background-image: url(../assets/CD_Cargo_Small_300.png); 
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .o2 {
    background-image: url(../assets/O2_Small_300.png); 
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .cez {
    background-image: url(../assets/CEZ_Small_300.png); 
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .lovochemie {
    background-image: url(../assets/Lovo_Small_300.png); 
    background-repeat: no-repeat;
    background-position: center;
  }

}

@media screen and (max-width: 850px) {
  .reference_logo {
    width: 200px;
    height: 200px;
  }

  .cd_cargo {
    background-image: url(../assets/CD_Cargo_Small_200.png); 
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .o2 {
    background-image: url(../assets/O2_Small_200.png); 
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .cez {
    background-image: url(../assets/CEZ_Small_200.png); 
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .lovochemie {
    background-image: url(../assets/Lovo_Small_200.png); 
    background-repeat: no-repeat;
    background-position: center;
  }
}
