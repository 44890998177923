

* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-margin-top: 100px;
  }

  :root {
    --darkgrey: #454140;
    --white: #f3f3f3;
    --yellow: #D2C001;
    --blue: #1D9DD9;
    --lightgrey: #DEDEDE;
    --black: #000;
    --whiteter: #ffffff;
    --black50:#00000066;
    --transparent: rgba(0, 0, 0, 0.50);
    --transparent-grey: rgba(255, 255, 255, .25);
    --transparent-white: rgba(255, 255, 255, .10);
    --grey: rgb(26, 26, 26);
    --navbar-color: #222B42;
    --background-color: #09132d;
  }


h1 {
    font-weight: 500;
}

h2 {
    font-weight: 400;
}

body{
    margin: 0px;
    padding: 0px;
    margin: 0px;
    margin: 0px;
    scroll-behavior: smooth;
    background-color: var(--background-color)
}

.menu-icons {
    display: none;
}

