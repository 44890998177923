

.NavbarItems {
    display: flex;
    justify-content: center;
    flex-direction: row;
    position:fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    width: 95%;
    z-index: 2;
    margin: 10px 0px;
    background-color: var(--navbar-color);
    border-radius: 15px;
    backdrop-filter: blur(px);
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 30%;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.nav-links{
    text-decoration: none;
    color: var(--white);
    cursor: pointer;
    padding: 14px 10px;
}
.nav-links:hover{
    border-bottom: 1px solid var(--white);
}
.fa-bars {
    color: var(--white);
    padding: 20px 10px;
    cursor: pointer;
}

.fa-times {
    color: var(--white);
    padding: 20px 10px;
    cursor: pointer;
}

.lang-switch {
    padding: 15px 10px; 
    position: fixed;
    right: 20px;
}
.lang-switch a {
    text-decoration: none;
    color: var(--white);
}

@media screen and (max-width: 850px){
    .NavbarItems{
        z-index: 2;
        position: fixed;
        
    }
    
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: var(--background-color);
        backdrop-filter: blur(4px);
        border-radius: 15px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -110%;
        align-items: stretch;
        padding: 60px 0px;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        margin-top: 0;
    
    }
    .nav-menu.active{
        left:0%;
        margin-top: 0;
       
    }
    .nav-links{
        display: block;
        width: 100%;
        padding: 2rem 0;
        color: var(--white);
        
    }
    .nav-links:hover{
        background-color: rgba(255, 255, 255, .25);
        backdrop-filter: blur(20px);
    }
    .menu-icons {
        display: block;
    }
    .nav-links:hover{
        border-bottom: none;
    }
    .fa-bars {
        color: var(--white);
    }
    .fa-times {
        color: var(--white);
    }
    .lang-switch a{
        color: var(--white);
    }
}