
.portfolio {
    width: 95%;
    height: auto;
    padding: 2.5%;
    margin: 2.5%;
    border-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    border-radius: 15px;
}

.portfolio::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--transparent-white);
    background-size: cover;
    backdrop-filter: blur(4px);
    z-index: -3;
    border-radius: 15px;
  }

.portfolio-element1 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: var(--white);
    border-radius: 15px;
    width: 20%;
    height: 300px;
    margin: 20px;
    transition: 1s;
}

.portfolio-element-top1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 1s;
    
}

.portfolio-element-bottom1 {
    color: var(--black);
    flex-direction: column;
    justify-content: center;
    transition: 1s;
    padding: 2.5%;
}

.portfolio-element-bottom1 button {
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border: none;
    color: var(--white);
    font-size: 1.3rem;
}

.portfolio-element-bottom1 p {
    margin: 5px;
}
.portfolio-element-top1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: 1s;
}

.portfolio-element-top1 i {
    font-size: 4rem;
    margin-right: 10px;
}



@media screen and (max-width: 850px){
    .portfolio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .portfolio-elemen1 {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--white);
        border-radius: 15px;
        width: 300px;
        height: 300px;
        transition: 1s;
        cursor: pointer;
    }

    

}