


.historie {
    width: 95%;
    height: auto;
    background-color: var(--white);
    padding: 2.5%;
    margin: 2.5%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.histori-left {
    text-align: center;
    padding: 2.5%;
    margin: 2.5%;
    font-size: 1.2rem;
    border-bottom: 3px solid var(--black);
}

.historie-right {
    padding: 2.5%;
    margin: 2.5%;
    font-size: 1.2rem;
}

@media screen and (max-width: 850px){
    .historie {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}