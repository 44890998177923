

.slider {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
    border-radius: 15px;
    font-size: 1.2rem;   
}

.all-text-content {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 80px;
    position: relative;
    font-weight: 100;
}


.all-text-content article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 1s linear;
}

.all-text-content article h2 {
    font-weight: 400;
}

article.active-slide {
    opacity: 1;
    transform: translateX(0);
}

article.last-slide {
    transform: translateX(-100%);
}

article.next-slide {
    transform: translateX(100%);
}


@media screen and (max-width: 1024px) {

    .all-text-content {
        width: 80%;
    }
    .all-text-content article {
        font-size: 0.6rem;
    }
    .slider {
        height: 60px;
    }
    
}