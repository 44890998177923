

.contact {
    width: 95%;
    height: auto;
    background-color: var(--white);
    padding: 2.5%;
    margin: 2.5%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2rem;
}

.contact h3 {
    margin: 15px 0px;
}

.contact i {
    font-size: 2rem;
    margin: 0 10px;
}

.contact p {
    margin: 5px 0;
}


.contact-address {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-address-top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.contact-left-element {
    margin-bottom: 30px;
}


.contact-left-element p a {
    text-decoration: none;
    color: #000;
}

.contact-left-element p {
    margin: 10px 0px;
}

.contact-left-element-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-left-element-top a {
    color: var(--black);
}

@media screen and (max-width: 850px) {
    .contact{
        flex-direction: column;
    }
}