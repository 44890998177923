

.portfolio-element1 button {
    background-color: transparent;
    color: var(--white);
    border: none;
    cursor: pointer;
    max-width: 350px;
    height:300px;
    border-radius: 15px;
    transition: 0.5s;
}

.portfolio-element1 button:hover {
    background-color: var(--white);
    color: var(--black);
}

.portfolio-element1 button p {
    margin: 0px;
}

.portfolio-element-top1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio-element-bottom1-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(10px);
    cursor: auto;
}


.portfolio-element-bottom1 {
    position: fixed;
    top: 10%;
    right: 8%;
    bottom: 10%;
    left: 8%;
    display: grid;
    grid-template-columns: 1fr 3fr 0.2fr;
    background-color: var(--white);
    border-radius: 15px;
    font-size: 1.3rem;
    z-index: 2;
}


.modal-text-left {
    padding-right: 10%;
    display: flex;
    align-items: center;
}

.modal-text-right {
    text-align: start;
    border-left: 2px solid var(--black);
    padding: 2.5%;
    overflow-y: auto;

}

.modal-text-right p {
    margin-bottom: 10px;
}
.close-button {
    position: relative;
    
}



.close-button button {
    height: 30px;
}

.close-button button i {
    color: var(--black);
    padding: 0px;
}

.portfolio-element-bottom1 p {
    margin: 2ex;
}

.portfolio-element-bottom1 h4 {
    margin: 2ex;
}


.portfolio-element-top1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: 1s;
}

.portfolio-element-top1 i {
    font-size: 4rem;
    margin-right: 10px;
}

.portfolio-element-top1 a {
    cursor: pointer;
}

.portfolio-element-top1 button {
    border: none;
    background-color: transparent;
    color: var(--white);
    font-size: 1.3rem;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: 1s;
}

.portfolio-element-top1 button:hover {
    border-bottom: 1px solid var(--black);
}
.portfolio-element1:hover .portfolio-element-top1 button {
    transition: 1s;
    color: var(--black);
}

@media screen and (max-width: 1280px){




    .portfolio-element1 {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--white);
        border-radius: 15px;
        height: 300px;
        width: auto;
        transition: 1s;
        cursor: pointer;
        
    }


    .portfolio-element-bottom1 {
        top: 10%;
        bottom: 8%;
        left: 10%;
        right: 8%;
   }

}



@media screen and (max-width: 850px){
    .portfolio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .portfolio-element1  {
        background-color: transparent;
        color: var(--white);
        border: none;
        cursor: pointer;
        width: 250px;
        height:300px;
        border-radius: 15px;
        transition: 0.5s;
    }

    .portfolio-element1 button:hover {
        background-color: inherit;
        color: inherit;
    }


    .portfolio-element-bottom1 {
        height: auto;
        top: 80px;
        left: 2.5%;
        right: 2.5%;
        font-size: 1rem;
        max-height: 75vh;
        padding-bottom: 30px;
        display: grid;
        grid-template-columns: 4fr 0.1fr;
        }

    .modal-text-right {
        height: auto;
        grid-column: 1 / 3;
        grid-row: 2;
        border-left: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow-y: auto;
    }

    .modal-text-right h5 {
        text-align: center;
    }

    .modal-text-right ul {
        padding-left: 30px;
    }

    .modal-text-left {
        display: flex;
        justify-content: center;
        padding: 0px;
        padding-top: 10px;
    }
    .modal-text-left h3{
        margin: 0;
    }
    .close-button {
        position: initial;
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
    }

}
