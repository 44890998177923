
.footer {
    margin: 0;
    padding: 50px 10%;
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer-widgets {
    color: var(--lightgrey);
}

.footer-copyright {
    color: var(--darkgrey);
}
