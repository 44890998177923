

.logo {
    margin: 0px;
    position: fixed;
    top: 160px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -99;
}

.logo img {
    height: 240px;
    filter: invert(1);
}




@media screen and (max-width: 850px) {
    .logo {
        top: 120px;
    }

    .logo img {
        height: 160px;
        filter: invert(1);
    }
}